import React, { useEffect, useState } from 'react';

const EjerciciosPage = () => {
  const diasSemana = ['DOMINGO', 'LUNES', 'MARTES', 'MIERCOLES', 'JUEVES', 'VIERNES', 'SABADO'];
  const fechaActual = new Date();
  const diaSemana = diasSemana[fechaActual.getDay()];

  const iframeUrl = `https://imagenes.website/PhotoGym/PG_ejercicios_V3.html?DIA=${encodeURIComponent(diaSemana)}`;
  console.log('URL del iframe:', iframeUrl);

  return (
    <div id="divTareas" className="page-content">
      <div style={{ height: 'calc(100vh - 100px)', overflow: 'hidden' }}>
        <iframe
          title="Ejercicios"
          src={iframeUrl}
          width="100%"
          height="100%" 
          frameBorder="0" 
        ></iframe>
      </div>
    </div>
  );
};

const AyudaPage = () => {
  return (
    <div id="divAyuda" className="page-content">
      <div style={{ height: 'calc(100vh - 100px)', overflow: 'hidden' }}>
        <iframe
          title="Ayuda"
          src="https://imagenes.website/PhotoGym/PG_ayuda.html"
          width="100%"
          height="100%" 
          frameBorder="0" 
        ></iframe>
      </div>
    </div>
  );
};

const App = () => {
  const [showAyuda, setShowAyuda] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    if (!isMobile) {
      document.body.classList.add('limit-width');
    }
  }, []);

  const openPhotoGym = () => {
    window.open('http://photo-gym.com/', '_blank');
  };

  const toggleAyuda = () => {
    setShowAyuda(!showAyuda);
  };

  const ayudaImageSrc = showAyuda ? "/images/PG_VOLVER.png" : "/images/PG_AYUDA.png";

  return (
    <div className="App">
        <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ width: '20%' }}></div>
            <img src="/images/PG_LOGO.png" style={{ width: '50%' }} alt="Logo" onClick={openPhotoGym} />
            <div style={{ width: '12%' }}></div>
            <img src={ayudaImageSrc} style={{ width: '8%' }} alt="Ayuda" onClick={toggleAyuda} />
            <div style={{ width: '10%' }}></div>
        </header>
        <main>
            {showAyuda ? <AyudaPage /> : <EjerciciosPage />}
        </main>
    </div>
  );
};

export default App;
